import { getFormProps, getInputProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import {
	json,
	type ActionFunctionArgs,
	type LoaderFunctionArgs,
	type MetaFunction,
} from '@remix-run/node'
import { Form, Link, useActionData } from '@remix-run/react'
import { HoneypotInputs } from 'remix-utils/honeypot/react'
import { z } from 'zod'
import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx'
import { CheckboxField, ErrorList, Field } from '#app/components/forms.tsx'
import { Button } from '#app/components/ui/button.tsx'
import { login, requireAnonymous } from '#app/utils/auth.server.ts'
import { checkHoneypot } from '#app/utils/honeypot.server.ts'
import { useIsPending } from '#app/utils/misc.tsx'
import { EmailSchema, PasswordSchema } from '#app/utils/user-validation.ts'
import { css } from '#styled-system/css/css.js'
import { handleNewSession } from './login.server.ts'

const LoginFormSchema = z.object({
	email: EmailSchema,
	password: PasswordSchema,
	remember: z.boolean().optional(),
})

export async function loader({ request }: LoaderFunctionArgs) {
	await requireAnonymous(request)
	return json({})
}

export async function action({ request }: ActionFunctionArgs) {
	await requireAnonymous(request)
	const formData = await request.formData()
	checkHoneypot(formData)
	const submission = await parseWithZod(formData, {
		schema: intent =>
			LoginFormSchema.transform(async (data, ctx) => {
				if (intent !== null) return { ...data, session: null }

				const session = await login(data)
				if (!session) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'Invalid email or password',
					})
					return z.NEVER
				}

				return { ...data, session }
			}),
		async: true,
	})

	if (submission.status !== 'success' || !submission.value.session) {
		return json(
			{ result: submission.reply({ hideFields: ['password'] }) },
			{ status: submission.status === 'error' ? 400 : 200 },
		)
	}

	const { session, remember } = submission.value

	return handleNewSession({
		request,
		session,
		remember: remember ?? false,
		redirectTo: '/icon-set/atlas',
	})
}

export default function LoginPage() {
	const actionData = useActionData<typeof action>()
	const isPending = useIsPending()

	const [form, fields] = useForm({
		id: 'login-form',
		constraint: getZodConstraint(LoginFormSchema),
		lastResult: actionData?.result,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: LoginFormSchema })
		},
		shouldRevalidate: 'onBlur',
	})

	return (
		<div
			className={css({
				position: 'relative',
				minHeight: '100vh',
				'@media(min-width:640px)': {
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				},
			})}
		>
			<div
				className={css({
					marginInline: 'auto',
					marginTop: '40px',
					width: '100%',
					maxWidth: '448px',
					paddingInline: '32px',
				})}
			>
				<h1
					className={css({
						fontSize: '24px',
						lineHeight: '32px',
					})}
				>
					Login
				</h1>

				<Form
					className={css({
						marginTop: '16px',
						display: 'flex',
						flexDirection: 'column',
						gap: '24px',
					})}
					method="POST"
					{...getFormProps(form)}
				>
					<HoneypotInputs />
					<Field
						labelProps={{ children: 'Email' }}
						inputProps={{
							...getInputProps(fields.email, { type: 'email' }),
							autoFocus: true,
							className: 'lowercase',
							autoComplete: 'email',
						}}
						errors={fields.email.errors}
					/>

					<Field
						labelProps={{ children: 'Password' }}
						inputProps={{
							...getInputProps(fields.password, {
								type: 'password',
							}),
							autoComplete: 'current-password',
						}}
						errors={fields.password.errors}
					/>

					<div
						className={css({
							display: 'flex',
							justifyContent: 'space-between',
						})}
					>
						<CheckboxField
							labelProps={{
								htmlFor: fields.remember.id,
								children: 'Remember me',
							}}
							buttonProps={getInputProps(fields.remember, {
								type: 'checkbox',
							})}
							errors={fields.remember.errors}
						/>
					</div>

					<ErrorList errors={form.errors} id={form.errorId} />

					<Button type="submit" disabled={isPending}>
						Log in
					</Button>
				</Form>
				<div
					className={css({
						marginTop: '24px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					})}
				>
					<Link
						to="/forgot-password"
						className={css({
							fontSize: '14px',
							lineHeight: '20px',
							color: 'gray11',
							_hover: {
								textDecoration: 'underline',
							},
						})}
					>
						Forgot password?
					</Link>
				</div>
			</div>
		</div>
	)
}

export const meta: MetaFunction = () => {
	return [{ title: 'Login to Notion Icons' }]
}

export function ErrorBoundary() {
	return <GeneralErrorBoundary />
}
